.error-field {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: var(--foundation-failedf75);
  font-family: var(--body-1-font-family);
  font-weight: var(--body-1-font-weight);
  font-size: var(--body-1-font-size);
  color: var(--darkdark-500);
  padding: 10px 10px;
  width: 100%;
  border-radius: 5px;
  margin-top: -10px;
}

.error-field-text {
  font-size: 12px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  text-wrap: wrap;
  max-width: 275px;
}

.error-field-icon {
  fill: var(--foundation-failedf700);
}
