.description-field {
  width: 100%;
  height: 100%;
  padding: 10px;
  background: #D1FFA4;
  border-radius: 5px;
  overflow: hidden;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  margin-top: -10px;
}

.description-field-text {
  font-size: 12px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  text-wrap: wrap;
  max-width: 275px;
}

.description-field-icon {
  fill: green;
}